import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { isAndroid, isIOS } from 'react-device-detect';

import download from "./download.svg";
import googlePlay2 from "./googlePlay2.png";
import thumbnail from "./icon.png";
import star from "./star.svg";
import starHalf from "./star-half.svg";
import posed from "react-pose";
import "./App.css";

const NOTIFICATION_LIST = [
  {
    time: "nu",
    text:
      "Imorgon är det Juldagen och Systembolaget är då stängt 🎅🏼🤶🏻 Så pass på att handla idag om du behöver nått!"
  },
  {
    time: "14 m sedan",
    text:
      "Imorgon är det Påskdagen 🐣 och Systembolaget är stängt, så om du behöver nått så får du handla idag!"
  },
  {
    time: "28 m sedan",
    text:
      "Imorgon är det Midsommar ☀️ och Systembolaget är då stängt, så pass på att handla snapsen idag!"
  },
  {
    time: "45 m sedan",
    text:
      "Imorgon är det Alla helgons dag 🎃 och Systembolaget är stängt, så pass på att handla idag om du skulle behöva nått!"
  },
];

const Box = posed.div({
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: "100%" }
});

ReactGA.initialize("UA-141792473-1");
ReactGA.pageview("/homepage");

function AppStoreBadge() {
  return (
    <a
    href="https://itunes.apple.com/se/app/hinner-jag-till-systemet/id1436175677?l=en&mt=8"
    alt="Ladda ner appen på AppStore"
    target="_blank"
    className="Download__link"
    rel="noopener noreferrer"
    onClick={() => {
      ReactGA.event({
        category: "Home",
        action: "Click App Store CTA - AppStore"
      });
    }}
  >
    <img className="Download__button" alt="" src={download} />
  </a>
  )
}

function GooglePlayBadge() {
  return (
    <a
    href="https://play.google.com/store/apps/details?id=com.sebastianekstrom.hinnerjagtillsystembolaget&hl=en"
    alt="Ladda ner appen på Google Play Store"
    target="_blank"
    className="Download__link"
    rel="noopener noreferrer"
    onClick={() => {
      ReactGA.event({
        category: "Home",
        action: "Click App Store CTA - Google Play"
      });
    }}
  >
    <img className="Download__button" alt="" src={googlePlay2} />
  </a>
  )
}

function App() {
  const [isVisibleIndex, setVisibility] = useState(-1);

  const timeOut = isVisibleIndex === -1 ? 300 : 3200;

  useEffect(() => {
    setTimeout(() => {
      if (NOTIFICATION_LIST.length === isVisibleIndex + 1) {
        return setVisibility(0);
      }
      setVisibility(isVisibleIndex + 1);
    }, timeOut);
  });

  return (
    <div className="App">
      <div className="App__content">
        <div className="App__wrapper">
          {NOTIFICATION_LIST.map((notification, index) => {
            return (
              <Box
                className="Notification"
                key={index}
                pose={isVisibleIndex === index ? "visible" : "hidden"}
              >
                <div className="Notification__header">
                  <img className="Notification__image" alt="" src={thumbnail} />
                  <h3 className="Notification__title">
                    Hinner jag till Systemet?
                  </h3>
                  <h5 className="Notification__timestamp">
                    {notification.time}
                  </h5>
                </div>
                <div className="Notification__body">
                  <p className="Notification__body-text">{notification.text}</p>
                </div>
              </Box>
            );
          })}
        </div>

        <div className="Download">
          {!isIOS && !isAndroid && 
            <>
            <div style={{
              marginRight: 10
            }}>
              <AppStoreBadge />
            </div>
            <GooglePlayBadge />
            </>
          }

          {isIOS &&
            <AppStoreBadge />
          }
          {isAndroid &&
           <GooglePlayBadge />
          }
        </div>

        <div className="Rating">
          <div className="Rating__star-container">
            <img className="Rating__star" alt="" src={star} />
            <img className="Rating__star" alt="" src={star} />
            <img className="Rating__star" alt="" src={star} />
            <img className="Rating__star" alt="" src={star} />
            <img className="Rating__star" alt="" src={starHalf} />
          </div>
          <p className="Rating__disclaimer">4.5 – 31 betyg</p>
        </div>

      </div>
    </div>
  );
}

export default App;
